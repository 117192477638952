import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  SavedIcon,
  toaster
} from "evergreen-ui";
import crmApiService from "@services/crmApiService";
import validator from "validator";


function InitBitrix({setBitrix}){
  let [domain, setDomain] = useState('');
  let [error, setError] = useState(null);

  function changeDomain(e){
    setError(null);
    setDomain(e.target.value);
  }

  function submitDisabled() {
    let valid = domain && !error;
    return !valid;
  }


 async function onSubmit(e) {
    try {
      e.preventDefault();
      if (submitDisabled()) return;
      let {data} = await crmApiService.connectBitrix({domain})
      setBitrix(data);
    } catch(e){
      console.error(e);
    }
  }

  function onBlurDomain(){
    let valid = validator.isLength(domain, { min: 10, max: undefined });
    if (!valid) {
      setError("Минимальная длина домена 10 символов!");
    }
  }

  return <Fragment>
  <TextInputField
    id="bitrix-crm-domain"
    label="Домен"
    placeholder="domain.bitrix24.ru"
    marginBottom="5px"
    value={domain}
    onChange={changeDomain}
    onBlur={onBlurDomain}
    validationMessage={error}
  />

  <Button
    onClick={onSubmit}
    disabled={submitDisabled()}
    iconAfter={SavedIcon}
    marginTop="15px"
    intent="success"
  >
    Сохранить
  </Button>
  </Fragment>
}

export default InitBitrix;
