import $api from "@http/index.js";

export default class ClientService {
  static get(gameId, crmCode) {
    return $api.get("/game/config", { params: { gameId, crmCode } });
  }
  static change(data) {
    console.log(data, "request data");

    return $api.put("/game/config", data);
  }
}
