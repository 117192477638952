import React, {Fragment}from 'react';
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  toaster
} from "evergreen-ui";
import {CopyToClipboard} from 'react-copy-to-clipboard';


function ConnectBitrix({resetBitrix}){
  const BitrixConnectLink = 'https://progms.pro/api/crm-api/bitrix/confirm'

 return  <Fragment>
           <Heading marginBottom="20px" size={600}>
             Путь для первоначальной установки:
           </Heading>


 <Pane marginBottom="20px" display="flex" justifyContent='space-between' alignItems='center' >
 <Heading  size={400}>
 {BitrixConnectLink}
 </Heading>

 <CopyToClipboard text={BitrixConnectLink}
  onCopy={() =>toaster.success('Скопировано!')}>
  <IconButton icon={ClipboardIcon} />
</CopyToClipboard>

 </Pane>
 <Button
   onClick={resetBitrix}
   iconBefore={ThDisconnectIcon}
   marginTop="20px"
   intent="danger"
 >
   Сбросить
 </Button> </Fragment>




}

export default ConnectBitrix;
