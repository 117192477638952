import React from "react";
import { Pane, Avatar, Heading } from "evergreen-ui";

function CrmList({ list, currentCrm, setCurrentCrm }) {
  return (
    <Pane display="flex" padding="10px" borderRadius="10px">
      {list.length !== 0 ? (
        list.map((c) => {
          return (
            <Pane
              cursor="pointer"
              backgroundColor={
                currentCrm === c.id ? "#adc2ff29" : "transparent"
              }
              padding="5px"
              borderRadius="5px"
              marginBottom="20px"
              key={c.id}
            >
              <Avatar onClick={()=>setCurrentCrm(c.id)} src={c.img} name={c.name} size={60} />{" "}
            </Pane>
          );
        })
      ) : (
        <Heading marginX="auto" size={600}>
          Нет подключённых CRM!
        </Heading>
      )}
    </Pane>
  );
}

export default CrmList;
