import React, { useState, useEffect } from "react";
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  UnorderedList,
  OrderedList,
  ListItem,
  toaster,
} from "evergreen-ui";
import crmApiService from "@services/crmApiService";
import validator from "validator";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Amo() {
  let [amo, setAmo] = useState(null);
  const AmoConnectedLink = "https://api.progms.pro/api/crm-api/amo/connected";

  async function recreateAmo() {
    try {
      await crmApiService
        .amoRecreate()
        .then((response) => {
          setAmo(response.data);
        })
        .catch((e) => {
          let message =
            e.response && e.response.data && e.response.data.message;
          console.log(message);
        });
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await crmApiService.getAmo();
        setAmo(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  function Disconnected({ setAmo }) {
    let [domain, setDomain] = useState("");
    let [domainError, setDomainError] = useState(null);
    let [secretKey, setSecretKey] = useState("");
    let [secretKeyError, setSecretKeyError] = useState(null);
    let [idIntegration, setIdIntegration] = useState("");
    let [idIntegrationError, setIdIntegrationError] = useState(null);
    let [codeAuth, setCodeAuth] = useState("");
    let [codeAuthError, setCodeAuthError] = useState(null);
    let [error, setError] = useState("");

    function onBlurField(name) {
      if (name === "domain") {
        let valid = validator.isLength(domain, { min: 10, max: undefined });
        if (!valid) {
          setDomainError("Минимальная длина домена 10 символов!");
        }
      } else if (name === "secretKey") {
        let valid = validator.isLength(secretKey, { min: 10, max: undefined });
        if (!valid) {
          setSecretKeyError("Минимальная длина секретного ключа 10 символов!");
        }
      } else if (name === "idIntegration") {
        let valid = validator.isLength(idIntegration, {
          min: 10,
          max: undefined,
        });
        if (!valid) {
          setIdIntegrationError("Минимальная длина ID 10 символов!");
        }
      } else if (name === "codeAuth") {
        let valid = validator.isLength(codeAuth, { min: 10, max: undefined });
        if (!valid) {
          setCodeAuthError("Минимальная длина кода авторизации 10 символов!");
        }
      }
    }

    function changeField(e, name) {
      if (name === "domain") {
        setDomainError(null);
        setDomain(e.target.value);
      } else if (name === "secretKey") {
        setSecretKeyError(null);
        setSecretKey(e.target.value);
      } else if (name === "idIntegration") {
        setIdIntegrationError(null);
        setIdIntegration(e.target.value);
      } else if (name === "codeAuth") {
        setCodeAuthError(null);
        setCodeAuth(e.target.value);
      }
    }
    function submitDisabled() {
      let valid =
        domain &&
        secretKey &&
        !domainError &&
        !secretKeyError &&
        idIntegration &&
        !idIntegrationError &&
        codeAuth &&
        !codeAuthError;
      return !valid;
    }

    function onSubmit(e) {
      e.preventDefault();
      if (submitDisabled()) return;

      console.log("onSubmit");
      connectAmo();
      // profileState.registration({ data: { email, password }, setError });
    }

    async function connectAmo() {
      try {
        let data = { domain, secretKey, idIntegration, codeAuth };
        await crmApiService
          .connectAmo(data)
          .then((response) => {
            setAmo(response.data);
          })
          .catch((e) => {
            let message =
              e.response && e.response.data && e.response.data.message;
            if (message) {
              setError(message);
            }
          });
      } catch (e) {
        console.error(e);
      }
    }

    return (
      <Pane padding="20px" flexGrow="1">
        <Heading marginBottom="20px" size={700}>
          Интеграция AmoCRM
        </Heading>

        <Pane background="green100" padding={24} marginBottom={16}>
          <OrderedList>
            <ListItem>
              Перейдите в <b>amoМаркет</b>, и нажмите кнопку{" "}
              <b>Создать интеграцию</b> (слева от WEB HOOKS), и выберите{" "}
              <b>внешняя интеграция</b>{" "}
            </ListItem>
            <ListItem>
              Скопируйте ниже <b>ссылку для интеграции</b> и вставьте в поле{" "}
              <b>ссылка для перенаправления</b>.В списке предоставьте доступ для
              нотификаций и данным аккаунта.Название и описание можете ввести
              любое.
            </ListItem>
            <ListItem>
              Перейдите во вкладку{" "}
              <b>Установленные - "Название расширения" - Ключи и доступы </b>
            </ListItem>
            <ListItem>
              Введите все в поля ниже и нажмите <b>Подключиться</b>
            </ListItem>
          </OrderedList>
        </Pane>

        {error ? <Alert intent="danger" title={error} marginY="15px" /> : null}

        <Heading marginBottom="20px" size={600}>
          Ссылка для интеграции:
        </Heading>

        <Pane
          marginBottom="20px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size={400}>{AmoConnectedLink}</Heading>

          <CopyToClipboard
            text={AmoConnectedLink}
            onCopy={() => toaster.success("Скопировано!")}
          >
            <IconButton icon={ClipboardIcon} />
          </CopyToClipboard>
        </Pane>

        <TextInputField
          id="amo-crm-domain"
          label="Домен"
          placeholder="domain.amocrm.ru"
          marginBottom="5px"
          value={domain}
          onChange={(e) => changeField(e, "domain")}
          onBlur={() => onBlurField("domain")}
          validationMessage={domainError}
        />

        <TextInputField
          id="amo-crm-secret-key"
          label="Секретный ключ"
          placeholder=""
          marginBottom="5px"
          value={secretKey}
          onChange={(e) => changeField(e, "secretKey")}
          onBlur={() => onBlurField("secretKey")}
          validationMessage={secretKeyError}
        />
        <TextInputField
          id="amo-crm-integration-id"
          label="ID интеграции"
          placeholder=""
          marginBottom="5px"
          value={idIntegration}
          onChange={(e) => changeField(e, "idIntegration")}
          onBlur={() => onBlurField("idIntegration")}
          validationMessage={idIntegrationError}
        />

        <Alert
          intent="none"
          title="Внимание: Код авторизации можно использовать лишь раз в течении 20 минут!"
          marginY="15px"
        />

        <TextInputField
          id="amo-crm-auth-code"
          label="Код авторизации"
          placeholder=""
          marginBottom="5px"
          value={codeAuth}
          onChange={(e) => changeField(e, "codeAuth")}
          onBlur={() => onBlurField("codeAuth")}
          validationMessage={codeAuthError}
        />
        <Button
          onClick={onSubmit}
          disabled={submitDisabled()}
          iconAfter={LinkIcon}
          marginTop="15px"
          intent="success"
        >
          Подключиться
        </Button>
      </Pane>
    );
  }

  function Redirect() {
    return (
      <Pane padding="20px" flexGrow="1">
        <Heading marginBottom="20px" size={700}>
          Интеграция AmoCRM
        </Heading>

        <Heading marginBottom="20px" size={700}>
          Перейдите по ссылке
        </Heading>

        <Button
          iconAfter={ArrowRightIcon}
          is="a"
          intent="success"
          marginTop="10px"
          target="_blank"
          href={amo.redirectLink}
        >
          Подвердить интеграцию
        </Button>
        <Button
          onClick={recreateAmo}
          iconBefore={ThDisconnectIcon}
          //
          marginTop="10px"
          marginLeft="10px"
          intent="danger"
        >
          Сбросить
        </Button>
      </Pane>
    );
  }

  function Connected({ setAmo }) {
    const AmoHookLink = "https://api.progms.pro/api/crm-api/amo/hook";

    return (
      <Pane padding="20px" flexGrow="1">
        <Heading marginBottom="20px" size={700}>
          Интеграция AmoCRM
        </Heading>

        <Pane background="green100" padding={24} marginBottom={16}>
          <OrderedList>
            <ListItem>
              Нажмите <b>WEB HOOKS</b>, и введите ссылку ниже, а также выберите
              из списка <b>сделка измененна, статус сделки измененн</b> и
              нажмите <b>Сохранить</b>
            </ListItem>
          </OrderedList>
        </Pane>

        <Heading marginBottom="20px" size={600}>
          Ссылка для хука:
        </Heading>

        <Pane
          marginBottom="20px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size={400}>{AmoHookLink}</Heading>

          <CopyToClipboard
            text={AmoHookLink}
            onCopy={() => toaster.success("Скопировано!")}
          >
            <IconButton icon={ClipboardIcon} />
          </CopyToClipboard>
        </Pane>

        <StatusIndicator color="success">Подключено</StatusIndicator>

        <br />
        <Button
          onClick={recreateAmo}
          iconBefore={ThDisconnectIcon}
          marginTop="20px"
          intent="danger"
        >
          Сбросить
        </Button>
      </Pane>
    );
  }

  function amoVisible() {
    if (amo.status === "disabled") {
      return <Disconnected setAmo={setAmo} />;
    } else if (amo.status === "redirect") {
      return <Redirect />;
    } else if (amo.status === "connected") {
      return <Connected setAmo={setAmo} />;
    }

    return <p>amo</p>;
  }

  return <div>{amo ? <>{amoVisible()}</> : null}</div>;
}
export default Amo;
