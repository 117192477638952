import React, { Fragment, useState, useEffect } from "react";
import { Pane, Heading } from "evergreen-ui";

import crmApiService from "@services/crmApiService";
import Loader from "@components/Blocks/Loader/Loader";
import CrmList from "@components/Blocks/CrmList/CrmList";
import amoImg from "@assets/images/amo.png";
import bitrixImg from "@assets/images/bitrix.png";
import GameConfigView from "./GameConfigView";

let apiList = [
  {
    name: "AmoCRM",
    img: amoImg,
    id: "amo",
  },
  {
    name: "Bitrix 24",
    img: bitrixImg,
    id: "bitrix",
  },
];

function GameConfigPage() {
  let [loaded, setLoaded] = useState(false);
  let [activatedCrmList, setActivatedCrmList] = useState([]);
  let [currentCrm, setCurrentCrm] = useState(null);

  const fetchCrmActivatedList = async () => {
    try {
      let { data } = await crmApiService.getActivatedCrmList();
      let filtredList = apiList.filter((a) => {
        let id = a.id;
        return data.includes(id);
      });
      setActivatedCrmList(filtredList);
      if (filtredList.length !== 0) {
        setCurrentCrm(filtredList[0].id);
      }
      setLoaded(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchCrmActivatedList();
  }, []);

  function Body() {
    return (
      <Fragment>
        {activatedCrmList.length !== 0 ? (
          <Fragment>
            <CrmList
              list={activatedCrmList}
              currentCrm={currentCrm}
              setCurrentCrm={setCurrentCrm}
            />
            <GameConfigView crmCode={currentCrm} />
          </Fragment>
        ) : (
          <Heading marginTop="50px" textAlign="center" size={800}>
            Нет подключённых CRM!
          </Heading>
        )}
      </Fragment>
    );
  }

  console.log(activatedCrmList, "activatedCrmList");
  return (
    <Pane marginTop="30px" marginX="auto" maxWidth="1000px">
      {!loaded ? <Loader /> : <Body />}
    </Pane>
  );
}

export default GameConfigPage;
