import { makeObservable, observable, action } from "mobx";
import ApiError from "@exceptions/apiError";
import clientService from "@services/clientService";

class Profile {
  profile = null;
  isAuthenticated = false;

  constructor() {
    makeObservable(
      this,
      {
        profile: observable,
        isAuthenticated: observable,
        login: action,
        registration: action,
        getProfile: action,
        logout: action,
      },
      { deep: true }
    );
  }

  async registration({ data, setError }) {
    try {
      await clientService
        .registration(data)
        .then((response) => {
          let { client, token } = response.data;
          localStorage.setItem("token", token);
          this.profile = client;
          this.isAuthenticated = true;
        })
        .catch((e) => {
          let message = e.response.data && e.response.data.message;
          if (message) {
            setError(message);
          }
        });
    } catch (e) {
      ApiError(e);
    }
  }

  async login({ data, setError }) {
    try {
      await clientService
        .login(data)
        .then((response) => {
          let { client, token } = response.data;
          localStorage.setItem("token", token);
          this.profile = client;
          this.isAuthenticated = true;
        })
        .catch((e) => {
          let message = e.response.data && e.response.data.message;
          if (message) {
            setError(message);
          }
        });
    } catch (e) {
      ApiError(e);
    }
  }

  async getProfile() {
    try {
      let token = localStorage.getItem("token");

      if (token) {
        await clientService
          .getProfile()
          .then((response) => {
            let client = response.data;
            this.profile = client;
            this.isAuthenticated = true;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } catch (e) {
      ApiError(e);
    }
  }

  logout() {
    this.isAuthenticated = false;
    this.profile = null;
    localStorage.removeItem("token");
  }
}

export default new Profile();
