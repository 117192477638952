import $api from "@http/index.js";

export default class ClientService {
  static registration(data) {
    return $api.post("/client/registration", data);
  }
  static login(data) {
    return $api.post("/client/login", data);
  }

  static getProfile() {
    return $api.get("/client/profile");
  }

  static changeProfile(data) {
    return $api.put("/client/profile", data);
  }
}
