import React, { useState, useEffect } from "react";
import Header from "@components/Blocks/Header/Header.jsx";
import "./App.sass";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router.jsx";
import profileState from "@store/profile";
import { observer } from "mobx-react-lite";

function App() {
  let [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        profileState.getProfile();
        setLoaded(true);
      } catch (e) {
        setLoaded(true);
      }
    };

    getData();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Header />

        {loaded ? <Router /> : null}
      </div>
    </BrowserRouter>
  );
}

export default observer(App);
