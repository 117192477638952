import React from "react";
import s from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import { Button, Pane, Heading, Avatar } from "evergreen-ui";
import { observer } from "mobx-react-lite";
import profileState from "@store/profile";

let authNav = [
  { link: "/crm-api", name: "Api CRM" },
  { link: "/users", name: "Пользователи" },
  { link: "/games", name: "Игры" },
  // { link: "/statistics", name: "Статистика" },
];

let unauthNav = [];

function Header() {
  let isAuthenticated = profileState.isAuthenticated;
  let profile = profileState.profile;
  let nav = isAuthenticated ? authNav : unauthNav;

  return (
    <Pane backgroundColor="#69b9ea">
      <Pane
        maxWidth="1160px"
        paddingY="15px"
        paddingX="15px"
        marginX="auto"
        marginY="0px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pane alignItems="center" display="flex">
          <Link className={s.logo} to="/">
            PRO <br />
            GMS <br />
            .RU
          </Link>
        </Pane>
        <Pane flex={1} alignItems="center" display="flex">
          {nav.map((n) => {
            return (
              <NavLink className={s.navlink} to={n.link}>
                {n.name}{" "}
              </NavLink>
            );
          })}
        </Pane>

        {isAuthenticated ? (
          <Avatar
            is={Link}
            to="/profile"
            src="https://www.shareicon.net/data/512x512/2016/05/24/769963_man_512x512.png"
            name={`${profile.firstName} ${profile.lastName}`}
            size={30}
          />
        ) : (
          <Pane>
            <Button is={Link} to="/login" marginRight="16px">
              Войти
            </Button>
            <Button is={Link} to="/registration" appearance="primary">
              Зарегистрироваться
            </Button>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

export default observer(Header);
