import React, { useState, useEffect } from "react";
import {
  Button,
  Pane,
  Text,
  TextInputField,
  SelectField,
  Alert,
  Paragraph,
  InlineAlert,
} from "evergreen-ui";
import validator from "validator";
import playerService from "@services/playerService";

function AddUser({ players, setPlayers, closeModal, crmId }) {
  let [users, setUsers] = useState([]);
  let [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => {
    let fetchData = async () => {
      try {
        let { data } = await playerService.getFreeCrmPlayers(crmId);
        setUsers(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  function filtredUsers() {
    let result = users.map((u) => {
      return { value: u.id, name: u.name };
    });

    if (result.length !== 0 && !selectedUser) {
      setSelectedUser(result[0].value);
    }

    return result;
  }

  function getSelectedUser() {
    return users.find((u) => u.id == selectedUser);
  }

  let roleList = [
    {
      value: "player",
      name: "Игрок",
    },
    {
      value: "admin",
      name: "Админ",
    },
   
  ];
  let [selectedRole, setSelectedRole] = useState(roleList[0].value);
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [error, setError] = useState("");

  function onBlurPassword() {
    let valid = validator.isLength(password, { min: 6, max: undefined });
    if (!valid) {
      setPasswordError("Мин. длинна пароля 6 символов!");
    }
  }
  function changePassword(e) {
    setPasswordError(null);
    setPassword(e.target.value);
  }

  function submitDisabled() {
    let valid = password && !passwordError;
    return !valid;
  }
  async function onSubmit(e) {
    try {
      e.preventDefault();
      if (submitDisabled()) return;
      let obj = {crmCode: crmId, crmId: selectedUser, password, role: selectedRole };
      await playerService
        .create(obj)
        .then((res) => {
          let data = res.data;
          console.log(data);
          setPlayers([...players, data]);
          closeModal();
        })
        .catch((e) => {
          let message =
            e.response && e.response.data && e.response.data.message;
          if (message) {
            setError(message);
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Pane is="form" onSubmit={onSubmit} paddingBottom="20px">
      {error ? (
        <Alert marginBottom="20px" intent="danger">
          {error}
        </Alert>
      ) : null}

      <SelectField
        onChange={(e) => setSelectedUser(e.target.value)}
        label="Выберите пользователя"
      >
        {filtredUsers().map((u) => {
          return (
            <option selected={u.value === selectedUser} value={u.value}>
              {u.name}
            </option>
          );
        })}
      </SelectField>

      <SelectField
        onChange={(e) => setSelectedRole(e.target.value)}
        label="Роль игрока"
      >
        {roleList.map((r) => {
          return (
            <option selected={r.value === selectedRole} value={r.value}>
              {r.name}
            </option>
          );
        })}
      </SelectField>

      {getSelectedUser() ? (
        <Paragraph fontSize="25px" marginY="20px">
          <Text fontSize="20px"> Email</Text>:
          <Text fontSize="25px"> {getSelectedUser().email}</Text>
        </Paragraph>
      ) : null}

      <Pane
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <TextInputField
          width="100%"
          label="Пароль"
          type="password"
          value={password}
          marginBottom="10px"
          onBlur={onBlurPassword}
          validationMessage={passwordError}
          onChange={changePassword}
        />
        <Button
          type="submit"
          disabled={submitDisabled()}
          marginTop="10px"
          flexGrow="1"
          intent="success"
        >
          Добавить
        </Button>
      </Pane>
    </Pane>
  );
}

export default AddUser;
