import React, { useState, useEffect } from "react";
import s from "./Reg.module.sass";
import { Pane, Heading, Button, TextInputField } from "evergreen-ui";
import profileState from "@store/profile";
import validator from "validator";

function Registration() {
  useEffect(() => {}, []);
  let [error, setError] = useState("");
  let [email, setEmail] = useState("");
  let [emailError, serEmailError] = useState(null);
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [confirmPassword, setConfirmPassword] = useState("");
  let [confirmPasswordError, setConfirmPasswordError] = useState(null);
  let [code, setCode] = useState("");
  let [codeError, setCodeError] = useState(null);

  function onBlurEmail() {
    let valid = validator.isEmail(email);
    if (!valid) {
      serEmailError("Email адрес не валиден!");
    }
  }
  function changeEmail(e) {
    serEmailError(null);
    setEmail(e.target.value);
  }

  function onBlurPassword() {
    let valid = validator.isLength(password, { min: 6, max: undefined });
    if (!valid) {
      setPasswordError("Мин. длинна пароля 6 символов!");
    }
  }
  function changePassword(e) {
    setPasswordError(null);
    setPassword(e.target.value);
  }

  function onBlurConfirmPassword() {
    let isEqual = password === confirmPassword;
    if (!isEqual) {
      setConfirmPasswordError("Пароли не совпадают!");
    }
  }
  function changeConfirmPassword(e) {
    setConfirmPasswordError(null);
    setConfirmPassword(e.target.value);
  }

  function onBlurCode() {
    let isEqual = password === confirmPassword;
    if (!isEqual) {
      setConfirmPasswordError("Пароли не совпадают!");
    }
  }
  function changeCode(e) {
    setCodeError(null);
    setCode(e.target.value);
  }

  function submitDisabled() {
    let valid =
      email &&
      password &&
      !emailError &&
      !passwordError &&
      password === confirmPassword &&
      confirmPassword &&
      !confirmPasswordError;

    return !valid;
  }
  function onSubmit(e) {
    e.preventDefault();
    if (submitDisabled()) return;
    profileState.registration({ data: { email, password, code }, setError });
  }
  return (
    <Pane
      marginTop="70px"
      width="350px"
      marginX="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="none"
      borderRadius="5px"
      paddingY="30px"
      paddingX="15px"
      is="form"
      backgroundColor="#69b9ea"
    >
      <h4 className={s.title}>Регистрация</h4>

      {error ? (
        <Heading textAlign="center" color="red" size={500}>
          {error}
        </Heading>
      ) : null}

      <Pane width="100%">
        <TextInputField
          marginTop="10px"
          label="Email"
          marginBottom="0px"
          width="100%"
          name="email"
          placeholder="Email"
          type="email"
          value={email}
          onBlur={onBlurEmail}
          validationMessage={emailError}
          onChange={changeEmail}
        />

        <TextInputField
          marginTop="10px"
          label="Пароль"
          marginBottom="0px"
          width="100%"
          name="password"
          placeholder="Пароль"
          type="password"
          value={password}
          onChange={changePassword}
          onBlur={onBlurPassword}
          validationMessage={passwordError}
        />
        <TextInputField
          marginTop="10px"
          label="Подтверждение пароля"
          marginBottom="0px"
          width="100%"
          name="confirmPassword"
          placeholder="Подтверждение пароля"
          type="password"
          value={confirmPassword}
          required
          onChange={changeConfirmPassword}
          onBlur={onBlurConfirmPassword}
          validationMessage={confirmPasswordError}
        />
        {/* <TextInputField
          marginTop="10px"
          label="Промокод"
          marginBottom="0px"
          width="100%"
          name="code"
          placeholder="Промокод"
          type="text"
          value={code}
          required
          onChange={changeCode}
          onBlur={onBlurCode}
          validationMessage={codeError}
        /> */}

        <Button
          onClick={onSubmit}
          width="100%"
          marginTop="20px"
          intent="success"
          type="submit"
          disabled={submitDisabled()}
        >
          Зарегистрироваться
        </Button>
      </Pane>
    </Pane>
  );
}

export default Registration;
