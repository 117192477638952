import React, { useState, useEffect } from "react";
import {
  Button,
  Pane,
  Text,
  TextInputField,
  SelectField,
  Alert,
  Paragraph,
  IconButton,
  InlineAlert,
  Spinner,
  TrashIcon,
} from "evergreen-ui";
import validator from "validator";
import playerService from "@services/playerService";

function AddUser({
  players,
  setPlayers,
  player,
  closeModal,
  crmId,
  deleteUser,
}) {
  let roleList = [
    {
      value: "player",
      name: "Игрок",
    },
    {
      value: "admin",
      name: "Админ",
    },
  ];
  let [selectedRole, setSelectedRole] = useState(roleList[0].value);
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [error, setError] = useState("");

  console.log(player, "pla");

  function onBlurPassword() {
    if (password) {
      let valid = validator.isLength(password, { min: 6, max: undefined });
      if (!valid) {
        setPasswordError("Мин. длинна пароля 6 символов!");
      }
    }
  }
  function changePassword(e) {
    setPasswordError(null);
    setPassword(e.target.value);
  }

  function submitDisabled() {
    let valid = !passwordError;
    return !valid;
  }
  async function onSubmit(e) {
    try {
      e.preventDefault();
      if (submitDisabled()) return;
      let obj = { playerId: player._id, password, role: selectedRole };
      await playerService
        .change(obj)
        .then((res) => {
          let newPlayer = res.data;
          let newPlayers = players.map((p) =>
            p._id === newPlayer._id ? newPlayer : p
          );
          setPlayers(newPlayers);
          closeModal();
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }

  async function deletePlayer(e) {
    try {
      e.preventDefault();

      if (window.confirm("Удалить игрока?")) {
        await playerService
          .delete(player._id)
          .then((res) => {
            deleteUser(player._id);
            closeModal();
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Pane is="form" onSubmit={onSubmit} paddingBottom="20px">
      {error ? (
        <Alert marginBottom="20px" intent="danger">
          {error}
        </Alert>
      ) : null}

      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Paragraph fontSize="25px" marginY="0px">
          <Text fontSize="20px"> Email</Text>:
          <Text fontSize="25px"> {player.email}</Text>
        </Paragraph>

        <IconButton
          icon={TrashIcon}
          intent="danger"
          onClick={deletePlayer}
          type="button"
          // marginRight={majorScale(2)}
        />
      </Pane>

      <Paragraph fontSize="25px" marginY="20px">
        <Text fontSize="20px"> Имя</Text>:
        <Text fontSize="25px"> {player.name}</Text>
      </Paragraph>

      <SelectField
        onChange={(e) => setSelectedRole(e.target.value)}
        label="Роль игрока"
      >
        {roleList.map((r) => {
          return (
            <option selected={r.value === selectedRole} value={r.value}>
              {r.name}
            </option>
          );
        })}
      </SelectField>

      <Pane
        width="100%"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <TextInputField
          width="100%"
          label="Пароль"
          type="password"
          value={password}
          marginBottom="10px"
          onBlur={onBlurPassword}
          validationMessage={passwordError}
          onChange={changePassword}
        />
        <Button
          type="submit"
          disabled={submitDisabled()}
          marginTop="10px"
          flexGrow="1"
          intent="success"
        >
          Сохранить
        </Button>
      </Pane>
    </Pane>
  );
}

export default AddUser;
