import React, { useState } from "react";
import {
  Button,
  Pane,
  Heading,
  Avatar,
  IconButton,
  CogIcon,
  Text,
  TextInputField,
  toaster,
} from "evergreen-ui";
import profileState from "@store/profile";
import profileService from "@services/clientService";

function Profile() {
  let profile = profileState.profile;
  let [firstName, setFirstName] = useState(profile.firstName);
  let [lastName, setLastName] = useState(profile.lastName);
  let [patronymic, setPatronymic] = useState(profile.patronymic);

  async function changeProfile() {
    try {
      let data = { firstName, lastName, patronymic };
      await profileService.changeProfile(data);
      toaster.success("Cохранено!");
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Pane marginX="auto" maxWidth="1000px" marginTop="50px">
      <Pane
        display="flex"
        justifyContent="space-between"
        marginBottom="20px"
        width="100%"
        textAlign="center"
      >
        <Heading size={900}>{profile.email}</Heading>

        <Button onClick={() => profileState.logout()}>Выйти</Button>
      </Pane>

      <Pane display="flex" justifyContent="start">
        <Pane padding="15px" backgroundColor="#adc2ff29" borderRadius="10px">
          <Avatar
            src="https://www.shareicon.net/data/512x512/2016/05/24/769963_man_512x512.png"
            name={`${profile.firstName} ${profile.lastName}`}
            size={100}
          />
        </Pane>
        <Pane
          padding="15px"
          backgroundColor="#adc2ff29"
          borderRadius="10px"
          flexGrow="1"
          marginLeft="20px"
        >
          <Pane display="flex" justifyContent="space-between">
            <TextInputField
              label="Имя"
              marginBottom="0px"
              width="32%"
              name="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // onBlur={onBlurPassword}
              // validationMessage={passwordError}
            />
            <TextInputField
              label="Фамилия"
              marginBottom="0px"
              width="32%"
              name="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              // onBlur={onBlurPassword}
              // validationMessage={passwordError}
            />
            <TextInputField
              label="Отчество"
              marginBottom="0px"
              width="32%"
              name="patronymic"
              type="text"
              value={patronymic}
              onChange={(e) => setPatronymic(e.target.value)}
              // onBlur={onBlurPassword}
              // validationMessage={passwordError}
            />
          </Pane>

          <Button
            onClick={changeProfile}
            intent="success"
            marginTop="20px"
            float="right"
          >
            Сохранить
          </Button>
        </Pane>
      </Pane>

      {/* <Heading marginBottom="20px" size={900}>
        Счёт
      </Heading>
      <Pane
        padding="15px"
        width="100%"
        backgroundColor="#adc2ff29"
        borderRadius="10px"
      >
        <Pane display="flex" alignItems="center" width="100%">
          <Heading size={500}>Баланс</Heading>
          <Pane flexGrow="1"></Pane>
          {profile.bill.balance === 0 ? (
            <Button intent="success">Запросить обещанный платёж</Button>
          ) : null}

          <Text marginLeft="20px">{profile.bill.balance} руб.</Text>
        </Pane>
      </Pane> */}
    </Pane>
  );
}

export default Profile;
