import React, { useState, useEffect } from "react";
import cl from "./Users.module.scss";
import {
  Pane,

} from "evergreen-ui";
import CrmList from "./CrmList";
import amoImg from "@assets/images/amo.png";
import bitrixImg from "@assets/images/bitrix.png";

import crmService from "@services/crmApiService";

import PlayerList from "./PlayerList";

let fullCrmList = [
  {
    name: "AmoCRM",
    img: amoImg,
    id: "amo",
  },
  {
    name: "Bitrix 24",
    img: bitrixImg,
    id: "bitrix",
  },
];


function Users() {
  let [activatedCrmList, setActivatedCrmList] = useState([]);
  let [currentCrm, setCurrentCrm] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await crmService.getActivatedCrmList();
        setActivatedCrmList(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);
  function filtredCrmList() {
    let result = fullCrmList.filter(
      (c) => activatedCrmList.indexOf(c.id) !== -1
    );
    if (result.length !== 0 && !currentCrm) {
      setCurrentCrm(result[0].id);
    }
    return result;
  }

  return (
    <Pane marginX="auto" paddingX="15px" paddingY="30px" maxWidth="1000px">
      <CrmList
        list={filtredCrmList()}
        currentCrm={currentCrm}
        setCurrentCrm={setCurrentCrm}
      />
      {filtredCrmList().length !== 0 ? <PlayerList crmId={currentCrm} /> : null}
    </Pane>
  );
}

export default Users;
