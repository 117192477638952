import $api from "@http/index.js";

export default class PlayerService {
  static create(data) {
    return $api.post("/player", data);
  }
  static change(data) {
    return $api.put("/player", data);
  }
  static delete(playerId) {
    return $api.delete("/player", { data: {playerId} });
  }
  static getByCrm(crmCode) {
    return $api.get("/player", { params: { crmCode } });
  }
  static getFreeCrmPlayers(crmCode) {
    return $api.get("/player/free", { params: { crmCode } });
  }
}
