import $api from "@http/index.js";

export default class CrmApiService {
  static getActivatedCrmList() {
    return $api.get("/crm-api/activatedList");
  }
  static connectAmo(data) {
    return $api.post("/crm-api/amo/connect", data);
  }
  static getAmo(data) {
    return $api.get("/crm-api/amo", data);
  }
  static getPipelines() {
    return $api.get("/crm-api/amo/pipelines");
  }
  static amoRecreate() {
    return $api.post("/crm-api/amo/recreate");
  }
  static getBitrix() {
    return $api.get("/crm-api/bitrix");
  }
  static connectBitrix(data) {
    return $api.post("/crm-api/bitrix/connect", data);
  }
  static recreateBitrix() {
    return $api.post("/crm-api/bitrix/recreate");
  }

  static connectedBitrix(data) {
    return $api.post("/crm-api/bitrix/connected", data);
  }
}
