import React from "react";
import {
  Button,
  Pane,
  Heading,
  Avatar,
  IconButton,
  CogIcon,
  PanelStatsIcon,
} from "evergreen-ui";
import { Link } from "react-router-dom";
import doaLogo from "./doa.jpg";

let games = [
  {
    img: doaLogo,
    name: "Dead or Alive",
    id: "gangsters",
  },
];

function GamePage() {
  return (
    <Pane marginX="auto" paddingX="15px" paddingY="30px" maxWidth="700px">
      <Heading size={700}>Статистика</Heading>

      <Pane width="100%" marginTop="40px">
        {games.map((g) => {
          return (
            <Pane
              padding="10px"
              borderRadius="10px"
              backgroundColor="#adc2ff29"
              width="100%"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              key={g.id}
            >
              <Avatar src={g.img} name={g.name} size={80} />
              <Heading marginLeft="20px" size={700}>
                {g.name}
              </Heading>
              <IconButton
                is={Link}
                to={`/statistics/${g.id}`}
                icon={PanelStatsIcon}
              />
            </Pane>
          );
        })}
      </Pane>
    </Pane>
  );
}

export default GamePage;
