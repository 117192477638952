import React, { useState } from "react";
import s from "./Login.module.sass";
import { Pane, Heading, TextInputField, Button } from "evergreen-ui";
import profileState from "@store/profile";
import validator from "validator";

function Registration() {
  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(null);
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [error, setError] = useState("");

  function onBlurEmail() {
    let valid = validator.isEmail(email);
    if (!valid) {
      setEmailError("Email адрес не валиден!");
    }
  }
  function changeEmail(e) {
    setEmailError(null);
    setEmail(e.target.value);
  }

  function onBlurPassword() {
    let valid = validator.isLength(password, { min: 6, max: undefined });
    if (!valid) {
      setPasswordError("Мин. длинна пароля 6 символов!");
    }
  }
  function changePassword(e) {
    setPasswordError(null);
    setPassword(e.target.value);
  }
  function submitDisabled() {
    let valid = email && password && !emailError && !passwordError;
    return !valid;
  }

  function onSubmit(e) {
    e.preventDefault();
    if (submitDisabled()) return;
    profileState.login({ data: { email, password }, setError });
  }

  return (
    <Pane
      marginTop="70px"
      width="350px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="none"
      borderRadius="5px"
      paddingY="30px"
      paddingX="15px"
      marginX="auto"
      is="form"
      backgroundColor="#69b9ea"
    >
      <h4 className={s.title}>Авторизация</h4>

      {error ? (
        <Heading textAlign="center" color="red" size={500}>
          {error}
        </Heading>
      ) : null}

      <Pane width="100%">
        <TextInputField
          marginTop="10px"
          label="Email"
          marginBottom="0px"
          width="100%"
          name="email"
          placeholder="Email"
          type="email"
          value={email}
          onBlur={onBlurEmail}
          validationMessage={emailError}
          onChange={changeEmail}
        />

        <TextInputField
          marginTop="10px"
          label="Пароль"
          marginBottom="0px"
          width="100%"
          name="password"
          placeholder="Пароль"
          type="password"
          value={password}
          onChange={changePassword}
          onBlur={onBlurPassword}
          validationMessage={passwordError}
        />

        <Button
          onClick={onSubmit}
          width="100%"
          marginTop="20px"
          intent="success"
          type="submit"
          disabled={submitDisabled()}
        >
          Войти
        </Button>
      </Pane>
    </Pane>
  );
}

export default Registration;
