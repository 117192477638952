import axios from "axios";

let API_URL = "http://localhost:4000/api";
let SOCKET_URL = "http://localhost:4000";
let URL = "http://localhost:4000";
let CLIENT_URL = "http://localhost:3000";

if (process.env.NODE_ENV === "production") {
  API_URL = "https://api.progms.pro/api";
  SOCKET_URL = "https://api.progms.pro";
}

export { API_URL, SOCKET_URL, URL, CLIENT_URL };

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("token");
  return config;
});

export default $api;
