import React, { useState, Fragment } from "react";
import {
  Button,
  Pane,
  Heading,
  IconButton,
  SelectField,
  MinusIcon,
  PlusIcon,
  Checkbox,
  Alert,
  toaster,
} from "evergreen-ui";

function ChangeConfigModal({
  crmCode,
  isLeadInit = false,
  initUniqueDeal = false,
  initDealsCount = 1,
  fetchConfig,
  saveConfig,
  pipelines = [],
  initPipeline,
  initStatus,
  leads = [],
  initLeadId,
  closeModal,
}) {
  let [isLead, setLead] = useState(isLeadInit);
  let [leadId, setLeadId] = useState(initLeadId);
  let [isUniqueDeal, setUniqueDeal] = useState(initUniqueDeal);
  let [deals, setDeals] = useState(initDealsCount);
  let [selectedPipeline, setSelectedPipeline] = useState(initPipeline);
  let [selectedStatus, setSelectedStatus] = useState(initStatus);

  React.useEffect(() => {
    if (isLead) {
      changeIsLead();
    }
  }, [isLead]);

  function getRequestData() {
    return {
      leadId: leadId,
      gameId: "gangsters",
      crmCode,
      pipeline_id: selectedPipeline,
      status_id: selectedStatus,
      dealsCount: deals,
      uniqueDeal: isUniqueDeal,
      isLead,
    };
  }

  function saveConfigHandler() {
    saveConfig(getRequestData());
    toaster.success("Сохранено!");
    closeModal();
  }

  async function changeIsLead() {
    await saveConfig(getRequestData());
    await fetchConfig();
  }

  function isLeadChange(e) {
    setLead(e.target.checked);
    if (e.target.checked) {
      changeIsLead();
    }
  }

  function changeDeals(count) {
    if (deals + count < 1) return;
    setDeals(deals + count);
  }

  let statusList = selectedPipeline
    ? pipelines.find((p) => p.id == selectedPipeline).statuses
    : [];

  console.log(leadId, "leadId");

  return (
    <Pane paddingBottom="15px">
      {crmCode === "bitrix" ? (
        <Fragment>
          {/* <Alert
            intent="none"
            title="Активируйте, если вы используете лиды, вместо классических воронок!"
          /> */}
          <Pane marginY="10px" display="flex" alignItems="center">
            <Heading marginRight="10px" htmlFor="change-unique-deal" size={400}>
              Использовать Лиды
            </Heading>
            <Checkbox
              id="change-unique-deal"
              size={400}
              checked={isLead}
              onChange={isLeadChange}
            />
          </Pane>
        </Fragment>
      ) : null}

      {isLead && crmCode === "bitrix" ? (
        <SelectField onChange={(e) => setLeadId(e.target.value)} label="Этапы">
          {leads.map((l) => {
            return (
              <option key={l.id} selected={l.id == leadId} value={l.id}>
                {l.name}
              </option>
            );
          })}
        </SelectField>
      ) : (
        <Fragment>
          <SelectField
            onChange={(e) => setSelectedPipeline(e.target.value)}
            label="Воронка"
          >
            {pipelines.map((p) => {
              return (
                <option
                  key={p.id}
                  selected={p.id == selectedPipeline}
                  value={p.id}
                >
                  {p.name}
                </option>
              );
            })}
          </SelectField>
          {statusList.length !== 0 ? (
            <SelectField
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Этап"
            >
              {statusList.map((s) => {
                return (
                  <option
                    key={s.id}
                    selected={s.id == selectedStatus}
                    value={s.id}
                  >
                    {s.name}
                  </option>
                );
              })}
            </SelectField>
          ) : null}
        </Fragment>
      )}

      <Heading size={400}>Количество сделок</Heading>

      <Pane
        marginBottom="20px"
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <IconButton onClick={() => changeDeals(-1)} icon={MinusIcon} />
        <Heading marginX="20px" size={500}>
          {deals}
        </Heading>
        <IconButton onClick={() => changeDeals(1)} icon={PlusIcon} />
      </Pane>

      <Pane marginBottom="10px" display="flex" alignItems="center">
        <Heading marginRight="10px" htmlFor="change-unique-deal" size={400}>
          Уникальная сделка
        </Heading>
        <Checkbox
          id="change-unique-deal"
          size={400}
          checked={isUniqueDeal}
          onChange={(e) => setUniqueDeal(e.target.checked)}
        />
      </Pane>

      <Button
        onClick={saveConfigHandler}
        width="100%"
        intent="success"
        type="submit"
      >
        Сохранить
      </Button>
    </Pane>
  );
}

export default ChangeConfigModal;
