import React, { useState } from "react";
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
} from "evergreen-ui";
import amoImg from "@assets/images/amo.png";
import bitrixImg from "@assets/images/bitrix.png";

import Amo from "./Amo/Amo";
import Bitrix from './Bitrix/Bitrix'

let apiList = [
  {
    name: "AmoCRM",
    img: amoImg,
    id: "amo",
  },
  // {
  //   name: "Bitrix 24",
  //   img: bitrixImg,
  //   id: "bitrix",
  // },
];

function APIList() {
  let [currentCrm, setCurrentCrm] = useState(apiList[0].id);

  function viewCrm(){
    if(currentCrm === 'amo'){
      return <Amo />;
    } else if(currentCrm === 'bitrix'){
      return <Bitrix/>;
    }
  }

  return (
    <Pane
      marginX="auto"
      paddingX="15px"
      paddingY="30px"
      maxWidth="1000px"
      display="flex"
    >
      <Pane
        display="flex"
        flexDirection="column"
        padding="10px"
        borderRadius="10px"
      >
        {apiList.map((c) => {
          return (
            <Pane
              cursor="pointer"
              backgroundColor={
                currentCrm === c.id ? "#adc2ff29" : "transparent"
              }
              padding="5px"
              borderRadius="5px"
              marginBottom="20px"
              key={c.id}
            >
              <Avatar onClick={()=>setCurrentCrm(c.id)} src={c.img} name={c.name} size={80} />{" "}
            </Pane>
          );
        })}
      </Pane>

      {viewCrm()}

    </Pane>
  );
}

export default APIList;
