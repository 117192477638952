import React, {Fragment, useState} from 'react';
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  toaster
} from "evergreen-ui";
import validator from "validator";
import crmApiService from "@services/crmApiService";



function ConfirmBitrix({resetBitrix, setBitrix}){
  let [error, setError] = useState('');
  let [id, setId] = useState('');
  let [idError, setIdError] = useState(null);
  let [secretKey, setSecretKey] = useState('');
  let [secretKeyError, setSecretKeyError] = useState(null);


  function changeField(e, name) {
    setError('');
    if (name === "id") {
      setIdError(null);
      setId(e.target.value);
    } else if (name === "secretKey") {
      setSecretKeyError(null);
      setSecretKey(e.target.value);
    }
  }

  function onBlurField(name) {
    if (name === "id") {
      let valid = validator.isLength(id, { min: 10, max: undefined });
      if (!valid) {
        setIdError("Минимальная длина ID 10 символов!");
      }
    } else if (name === "secretKey") {
      let valid = validator.isLength(secretKey, { min: 10, max: undefined });
      if (!valid) {
        setSecretKeyError("Минимальная длина секретного ключа 10 символов!");
      }
    }
  }

  function submitDisabled() {
    let valid =
      id &&
      secretKey &&
      !idError &&
      !secretKeyError;
    return !valid;
  }

  async function onSubmit(e){
    try {
      e.preventDefault();
      if (submitDisabled()) return;
      // connectedBitrix
      let {data} = await crmApiService.connectedBitrix({clientId: id, secretKey })
      setBitrix(data);
    } catch(e){
      let message = e.response && e.response.data && e.response.data.message;
      if(message){
          setError(message);
      }
      console.error(e);
    }
  }


  return <Fragment>
  {error ? <Alert intent="danger" title={error} marginY="15px" /> : null}


  <TextInputField
    id="bitrix-crm-domain"
    label="ID клиента"
    marginBottom="5px"
    value={id}
    onChange={(e)=>changeField(e, 'id')}
    onBlur={() => onBlurField("id")}
    validationMessage={idError}
  />
  <TextInputField
    id="bitrix-crm-domain"
    label="Секретный ключ клиента"
    marginBottom="5px"
    value={secretKey}
    onChange={(e)=>changeField(e, 'secretKey')}
    onBlur={() => onBlurField("secretKey")}
    validationMessage={secretKeyError}
  />

  <Pane     marginTop="20px"
 display="flex" alignItems='center'>
  <Button
    onClick={onSubmit}
    disabled={submitDisabled()}
    iconAfter={LinkIcon}
    marginRight="15px"
    intent="success"
  >
    Подключиться
  </Button>

  <Button
    onClick={resetBitrix}
    iconBefore={ThDisconnectIcon}
    intent="danger"
  >
    Сбросить
  </Button>

  </Pane>




    </Fragment>
}

export default ConfirmBitrix;
