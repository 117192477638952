import React, { useEffect, useState } from "react";
import './Pc.sass';
import $api from "../../http/index";

export default function Pc() {
  let [list, setList] = useState([]);

  const fetchData = async () => {
    try {
      let response = await $api.get("/client/supersecretlinkblasdasdadadada");
      let data = response.data;
      setList(data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function vList() {
    return (
      <div>
        <table className='table-list'>
          <tr>
            <th>Email</th>
            <th>Имя</th>
            <th>Фамилия</th>
            <th>Промокод</th>
          </tr>

          {list.map((c) => {
            return (
              <tr>
                <td>{c.email}</td>
                <td>{c.firstName}</td>
                <td>{c.lastName}</td>
                <td>{c.promocode}</td>
              </tr>
            );
          })}
        </table>
      </div>
    );
  }

  return <div>{list.length !== 0 ? vList() : null}</div>;
}
