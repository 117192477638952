import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import profileState from "@store/profile";
import APIList from "@pages/APIList/APIList.jsx";
import Registration from "@pages/Reg/Reg.jsx";
import Login from "@pages/Login/Login";
import Users from "@pages/Users/Users";
import Games from "@pages/Games/Games";
import GameConfigPage from "@pages/GameConfigPage/GameConfigPage";
import Statistics from "@pages/Statistics/Statistics";
import Profile from "@pages/Profile/Profile";
import GameStatistics from "@pages/GameStatistics/GameStatistics";
import Main from "@pages/Main/Main";
import Pc from "@pages/Pc/Pc";

let unauthRoutes = [
  {
    path: "/kNajb407ma3l762",
    exact: false,
    component: Pc,
  },

  {
    path: "/registration",
    exact: false,
    component: Registration,
  },
  {
    path: "/login",
    exact: false,
    component: Login,
  },
  {
    path: "/",
    exact: true,
    component: Main,
  },
];

let authRoutes = [
  {
    path: "/crm-api",
    exact: true,
    component: APIList,
  },
  {
    path: "/users",
    exact: true,
    component: Users,
  },
  {
    path: "/games",
    exact: true,
    component: Games,
  },
  {
    path: "/games/:id",
    exact: true,
    component: GameConfigPage,
  },
  {
    path: "/statistics",
    exact: true,
    component: Statistics,
  },
  {
    path: "/statistics/:id",
    exact: true,
    component: GameStatistics,
  },

  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: "/",
    exact: true,
    component: Main,
  },
];

function Router() {
  let isAuthenticated = profileState.isAuthenticated;
  let routes = isAuthenticated ? authRoutes : unauthRoutes;
  return (
    <Switch>
      {routes.map((r) => {
        let Component = r.component;
        return (
          <Route exact={r.exact} path={r.path}>
            <Component />
          </Route>
        );
      })}

      <Redirect to="/" />
    </Switch>
  );
}

export default observer(Router);
