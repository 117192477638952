import React, { useState, useEffect } from "react";
import {
  Pane,
  Heading,
  Text,
  IconButton,
  CogIcon,
  Dialog,
  // toaster,
} from "evergreen-ui";
import { useParams } from "react-router-dom";
import configService from "@services/configService";

import ChangeConfigModal from "./ChangeConfigModal";

function GameConfigView({ crmCode }) {
  console.log(crmCode, "crmCode");
  let [loaded, setLoaded] = useState(false);
  let { id: gameId } = useParams();
  let [stages, setStages] = useState([]);
  let [leads, setLeads] = useState([]);
  let [config, setConfig] = useState(null);
  let [showModal, setShowModal] = useState(false);

  async function fetchConfig() {
    try {
      let { data } = await configService.get(gameId, crmCode);

      console.log(data, "data");

      setConfig(data.config);
      setStages(data.stages);
      setLeads(data.leads);

      //   setConfig(data.config);

      //   let initCrm;
      //   let connectedList = { bitrix: false, amo: false };
      //   if (data.bitrixPipelines) {
      //     initCrm = "bitrix";
      //     setBitrixPipelines(data.bitrixPipelines);
      //     connectedList.bitrix = true;
      //   }
      //   if (data.amoPipelines) {
      //     initCrm = "amo";
      //     setAmoPipelines(data.amoPipelines);
      //     connectedList.amo = true;
      //   }
      //   setConnectedCrmList(connectedList);
      //   setCurrentCrm(initCrm);

      setLoaded(true);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  async function saveConfig(requestData) {
    try {
      console.log(requestData, "requestData save config");

      let { data } = await configService.change(requestData);
      setConfig(data);
    } catch (e) {
      console.error(e);
    }
  }

  function body() {
    let initPipeline =
      crmCode === "bitrix"
        ? config.accrualStageBitrix.pipeline_id
        : config.accrualStageAmo.pipeline_id;
    let initStatus =
      crmCode === "bitrix"
        ? config.accrualStageBitrix.status_id
        : config.accrualStageAmo.status_id;

    return (
      <Pane
        marginX="auto"
        paddingX="15px"
        paddingY="30px"
        maxWidth="1000px"
        backgroundColor="#adc2ff29"
        borderRadius="10px"
      >
        <Heading marginBottom="20px" size={800}>
          {config.name} : Конфигурация
        </Heading>

        <Pane
          marginBottom="30px"
          paddingX="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Text fontWeight="500" fontSize="20px">
            Игра
          </Text>
          <Text fontWeight="500" fontSize="20px">
            CRM Cобытие
          </Text>
        </Pane>

        <Pane
          backgroundColor="#adc2ff29"
          alignItems="center"
          marginTop="10px"
          display="flex"
          justifyContent="space-between"
          paddingX="10px"
          paddingY="5px"
          borderRadius="5px"
        >
          <Text>Начисление зведы/креста</Text>
          <IconButton onClick={() => setShowModal(true)} icon={CogIcon} />
        </Pane>

        <Dialog
          isShown={showModal}
          title="Выбор этапа сделки"
          hasFooter={false}
          onCloseComplete={() => setShowModal(false)}
        >
          <ChangeConfigModal
            fetchConfig={fetchConfig}
            saveConfig={saveConfig}
            isLeadInit={config.isLead}
            crmCode={crmCode}
            pipelines={stages}
            initPipeline={initPipeline}
            initStatus={initStatus}
            leads={leads}
            initLeadId={config.leadId}
            initDealsCount={config.dealsCount}
            initUniqueDeal={config.uniqueDeal}
            closeModal={() => setShowModal(false)}
          />
          {/* modal */}
          {/* <ChangeConfig
            pipelines={pipelines}
            saveConfig={saveChangeConfig}
            initPipeline={initPipeline}
            initStatus={initStatus}
            crmCode={currentCrm}
            initDealsCount={config.dealsCount}
            uniqueDeal={config.uniqueDeal}
            isLeadInit={config.isLead}
            fetchConfig={fetchConfig}
            */}
        </Dialog>
      </Pane>
    );
  }

  return <Pane>{loaded ? body() : null}</Pane>;
}

export default GameConfigView;
