import React, { useState, useEffect } from "react";
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  toaster
} from "evergreen-ui";
import crmApiService from "@services/crmApiService";

import InitBitrix from './InitBitrix';
import ConnectBitrix from './ConnectBitrix';
import ConfirmBitrix from './ConfirmBitrix';
import ConnectedBitrix from './ConnectedBitrix';



function Bitrix(){
  let [bitrix, setBitrix] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await crmApiService.getBitrix();
        setBitrix(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);


 async  function resetBitrix(){
    try {
      let {data} = await crmApiService.recreateBitrix();
      setBitrix(data);
    } catch(e){
      console.error(e);
    }
  }

  function viewByStatus(){
    if(!bitrix) return null;

    let status = bitrix.status;

    if(status === 'init'){
      return <InitBitrix setBitrix={setBitrix}/>
    } else if(status === 'connect'){
    return <ConnectBitrix resetBitrix={resetBitrix}/>
  } else if(status === 'confirm'){
    return <ConfirmBitrix setBitrix={setBitrix} resetBitrix={resetBitrix}/>
  } else if(status === 'connected'){
    return <ConnectedBitrix resetBitrix={resetBitrix}/>
  }
}

  console.log(bitrix, 'bitrix')

 return  <Pane padding="20px" flexGrow="1">
    <Heading marginBottom="20px" size={700}>
      Интеграция Bitrix 24
    </Heading>

    {viewByStatus()}
  </Pane>

}

export default Bitrix;
