import React from "react";
import {
  Button,
  Pane,
  Heading,
  Avatar,
  IconButton,
  CogIcon,
  PanelStatsIcon,
} from "evergreen-ui";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function GameStatistics() {
  let { id } = useParams();

  console.log(id, "id");
  return (
    <Pane
      marginX="auto"
      paddingX="15px"
      paddingY="30px"
      maxWidth="1000px"
      backgroundColor="#adc2ff29"
      borderRadius="10px"
      marginTop="50px"
    >
      {/* <Heading size={700}>Статистика</Heading> */}

      
      stat game
    </Pane>
  );
}

export default GameStatistics;
