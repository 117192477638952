import React, { useState, useEffect } from "react";
import { Button, Pane, Heading, Dialog, AddIcon } from "evergreen-ui";
import playerService from "@services/playerService";
import TablePlayers from "./TablePlayers";
import AddUser from "./AddUser";
import ChangeUser from "./ChangeUser";

function PlayerList({ crmId }) {
  let [players, setPlayers] = useState([]);
  let [selectedPlayer, setSelectedPlayer] = useState(null);
  let [changePlayer, setChangePlayer] = useState(null);
  let [addUserModal, setAddUserModal] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await playerService.getByCrm(crmId);
        setPlayers(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [crmId]);

  function showChangePlayer(player) {
    setSelectedPlayer(player);
    setChangePlayer(true);
  }

  function deleteUser(id) {
    let filtredPlayers = players.filter((p) => p._id !== id);
    setPlayers(filtredPlayers);
  }

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between" width="100%">
        <Heading size={700}>Пользователи</Heading>
        <Button
          onClick={() => setAddUserModal(true)}
          iconAfter={AddIcon}
          intent="success"
        >
          Добавить
        </Button>
      </Pane>
      {players.length === 0 ? (
        <Heading size={600} textAlign="center" marginTop="30px">
          Список игроков пуст!
        </Heading>
      ) : (
        <TablePlayers showChangePlayer={showChangePlayer} list={players} />
      )}

      <Dialog
        isShown={addUserModal}
        title="Добавить игрока"
        hasFooter={false}
        onCloseComplete={() => setAddUserModal(false)}
      >
        <AddUser
          crmId={crmId}
          closeModal={() => setAddUserModal(false)}
          players={players}
          setPlayers={setPlayers}
        />
      </Dialog>

      <Dialog
        isShown={changePlayer}
        title="Изменить игрока"
        hasFooter={false}
        onCloseComplete={() => {
          setChangePlayer(false);
        }}
      >
        <ChangeUser
          closeModal={() => {
            setChangePlayer(false);
          }}
          players={players}
          setPlayers={setPlayers}
          deleteUser={deleteUser}
          player={selectedPlayer}
        />
      </Dialog>
    </Pane>
  );
}

export default PlayerList;
