import React from 'react';
import {
  Button,
  Pane,
  Avatar,
  Heading,
  Text,
  TextInputField,
  Dialog,
  Alert,
  LinkIcon,
  ThDisconnectIcon,
  ArrowRightIcon,
  StatusIndicator,
  ClipboardIcon,
  IconButton,
  toaster
} from "evergreen-ui";
import {CopyToClipboard} from 'react-copy-to-clipboard';


const BitrixHookLink = 'https://progms.pro/api/crm-api/bitrix/hook';

function ConnectedBitrix({resetBitrix}){


    return(
      <Pane flexGrow="1">
        <Heading marginBottom="20px" size={600}>
          Ссылка для хука:
        </Heading>

        <Pane marginBottom="20px" display="flex" justifyContent='space-between' alignItems='center' >
        <Heading  size={400}>
        {BitrixHookLink}
        </Heading>

        <CopyToClipboard text={BitrixHookLink}
         onCopy={() =>toaster.success('Скопировано!')}>
         <IconButton icon={ClipboardIcon} />
       </CopyToClipboard>
        </Pane>
        <StatusIndicator color="success">Подключено</StatusIndicator>
        <br />
        <Button
          onClick={resetBitrix}
          iconBefore={ThDisconnectIcon}
          marginTop="20px"
          intent="danger"
        >
          Отключиться
        </Button>
      </Pane>
    )
}

export default ConnectedBitrix;
