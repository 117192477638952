import React, { useState } from "react";
import { Table, IconButton, EditIcon } from "evergreen-ui";

function TablePlayers({ list, showChangePlayer }) {
  let [sort, setSort] = useState("role");

  function filtredList() {
    let result = [...list];
    if (sort === "role") {
      result.sort(function (a, b) {
        return a.role > b.role ? 1 : -1;
      });
    } else if (sort === "name") {
      result.sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      });
    } else if (sort === "login") {
      result.sort(function (a, b) {
        return a.login > b.login ? 1 : -1;
      });
    }

    return result;
  }

  return (
    <Table.Body marginTop="20px">
      <Table.Head>
        <Table.TextCell cursor="pointer" onClick={() => setSort("role")}>
          Роль
        </Table.TextCell>
        <Table.TextCell cursor="pointer" onClick={() => setSort("name")}>
          Имя
        </Table.TextCell>
        <Table.TextCell cursor="pointer" onClick={() => setSort("login")}>
          Email
        </Table.TextCell>
        <Table.TextCell>Изменить</Table.TextCell>
      </Table.Head>
      <Table.Body>
        {filtredList().map((p) => {
          return (
            <Table.Row
              key={p._id}
              intent={p.role === "admin" ? "success" : "warning"}
            >
              <Table.TextCell>
                {p.role === "admin" ? "Админ" : "Игрок"}
              </Table.TextCell>
              <Table.TextCell>{p.name}</Table.TextCell>
              <Table.TextCell>{p.email}</Table.TextCell>
              <Table.TextCell>
                <IconButton
                  onClick={() => {
                    showChangePlayer(p);
                  }}
                  marginLeft="10px"
                  icon={EditIcon}
                />
              </Table.TextCell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Body>
  );
}

export default TablePlayers;
