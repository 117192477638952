import React, { useState } from "react";
import s from "./Main.module.sass";
import Ticker from "react-ticker";
import Typing from "react-typing-animation";
import PageVisibility from "react-page-visibility";
import { Zoom } from "react-slideshow-image";

import gp from "@assets/images/gp.png";
import pl from "@assets/images/pl.png";
import smile from "@assets/images/smile.png";
import arms from "@assets/images/arms.png";
import biceps from "@assets/images/biceps.png";
import peoples from "@assets/images/peoples.png";
import singer from "@assets/images/singer.png";
import thu from "@assets/images/thu.png";
import medal from "@assets/images/medal.png";
import bomb from "@assets/images/bomb.png";

let benefits = [
  {
    text: "Поднять настроение сотрудников",
    emoji: smile,
    id: 1,
  },
  {
    text: "Сделать рутинные процессы легкими для восприятия",
    emoji: arms,
    id: 2,
  },
  {
    text: "Увеличить производительность сотрудника минимум на 30%",
    emoji: biceps,
    id: 3,
  },
  {
    text: "командообразованию",
    emoji: peoples,
    id: 4,
  },
  {
    text: "усилить вовлеченность в работу на удаленке",
    emoji: singer,
    id: 5,
  },
  {
    text: "быть интересным работодателем для молодежи 20-25 лет",
    emoji: thu,
    id: 6,
  },
  {
    text: "реанимировать потухших сотрудников",
    emoji: medal,
    id: 7,
  },
  {
    text: "снизить контроль за сотрудниками",
    emoji: bomb,
    id: 8,
  },
];

let tickerText =
  "Чем больше вы обязаны что-либо делать, тем больше вы воспринимаете это как работу. Чем меньше вы обязаны что-либо делать, тем больше вы воспринимаете это как игру.";

function Main() {
  let [benefit, setBenefit] = useState(benefits[0]);

  function nextBenefit() {
    let nextBenefitId = benefit.id + 1;
    let nextBenefit = benefits.find((b) => b.id == nextBenefitId);

    if (nextBenefit) {
      setBenefit(nextBenefit);
    } else {
      setBenefit(benefits[0]);
    }
  }

  function nextTimeOut() {
    setTimeout(() => {
      nextBenefit();
    }, 2000);
  }

  function BenefitText({ benefit }) {
    console.log("BenefitText rerender");
    return (
      <div className={s.benefitText}>
        <Typing
          onFinishedTyping={nextTimeOut}
          speed={100}
          // startDelay={100}
          cursorClassName={s.titleCursor}
        >
          {benefit.text}
        </Typing>
      </div>
    );
  }

  return (
    <div className={s.main}>
      <div className={s.banner}>
        <div className={s.emojes}>
          <img src={gp} className={s.gp} />
          <div className={s.symbol}>+</div>
          <img src={pl} className={s.pl} />
          <div className={s.symbol}>=</div>
          <img src={benefit.emoji} className={`${s.res}`} />
        </div>

        <div className={s.title}>Мультиплеерные онлайн игры помогают:</div>
        <BenefitText benefit={benefit} />
      </div>

      <div className={s.ticker}>
        <Ticker mode="await" height="26" speed={5}>
          {() => (
            <>
              <p> {tickerText}</p>
            </>
          )}
        </Ticker>
      </div>
    </div>
  );
}

export default Main;
